import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy" style={{ fontFamily: "Arial, sans-serif", lineHeight: "1.6", padding: "2rem" }}>
      <h1 style={{ fontSize: "2.5rem", marginBottom: "1rem", color: "#151718" }}>Privacy Policy</h1>
      <p style={{ color: "#8E8E93" }}>
        Effective Date: <strong>January 5, 2025</strong>
      </p>

      <section style={{ marginBottom: "2rem" }}>
        <h2 style={{ fontSize: "1.5rem", color: "#CD7F32", marginBottom: "0.5rem" }}>1. Introduction</h2>
        <p>
          Welcome to BarbersBook. This Privacy Policy explains how we collect, use, and protect your personal
          information when you use our app. By using BarbersBook, you agree to the practices outlined in this policy.
        </p>
      </section>

      <section style={{ marginBottom: "2rem" }}>
        <h2 style={{ fontSize: "1.5rem", color: "#CD7F32", marginBottom: "0.5rem" }}>2. Information We Collect</h2>
        <ul>
          <li><strong>Personal Information:</strong> When you create an account, we may collect your name, email address, and phone number.</li>
          <li><strong>Financial Data:</strong> Information related to earnings, expenses, and services logged in the app.</li>
          <li><strong>Device Information:</strong> Data about your device, such as the operating system, IP address, and app usage.</li>
        </ul>
      </section>

      <section style={{ marginBottom: "2rem" }}>
        <h2 style={{ fontSize: "1.5rem", color: "#CD7F32", marginBottom: "0.5rem" }}>3. How We Use Your Information</h2>
        <p>
          We use the information we collect to:
        </p>
        <ul>
          <li>Provide and improve the BarbersBook app functionality.</li>
          <li>Track your earnings, expenses, and waitlist management.</li>
          <li>Communicate with you regarding updates, support, or promotional offers.</li>
        </ul>
      </section>

      <section style={{ marginBottom: "2rem" }}>
        <h2 style={{ fontSize: "1.5rem", color: "#CD7F32", marginBottom: "0.5rem" }}>4. Sharing Your Information</h2>
        <p>
          We do not sell your personal information. However, we may share your data with third-party services for:
        </p>
        <ul>
          <li>Payment processing (if applicable).</li>
          <li>Analytics to improve app performance.</li>
          <li>Compliance with legal obligations.</li>
        </ul>
      </section>

      <section style={{ marginBottom: "2rem" }}>
        <h2 style={{ fontSize: "1.5rem", color: "#CD7F32", marginBottom: "0.5rem" }}>5. Data Security</h2>
        <p>
          We implement industry-standard measures to protect your data. However, no method of transmission or storage is completely secure. Use the app at your own risk.
        </p>
      </section>

      <section style={{ marginBottom: "2rem" }}>
        <h2 style={{ fontSize: "1.5rem", color: "#CD7F32", marginBottom: "0.5rem" }}>6. Your Rights</h2>
        <p>
          You have the right to access, update, or delete your personal data. Contact us at <a href="mailto:support@barbersbook.com">support@barbersbook.com</a> for assistance.
        </p>
      </section>

      <section style={{ marginBottom: "2rem" }}>
        <h2 style={{ fontSize: "1.5rem", color: "#CD7F32", marginBottom: "0.5rem" }}>7. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be communicated through the app or via email.
        </p>
      </section>

      <section style={{ marginBottom: "2rem" }}>
        <h2 style={{ fontSize: "1.5rem", color: "#CD7F32", marginBottom: "0.5rem" }}>8. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@barbersbook.com">support@barbersbook.com</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
