import React from "react";
import homeImage from "./assets/home.png";
import servicesImage from "./assets/services.png";
import expensesImage from "./assets/expenses.png";
import waitlistImage from "./assets/waitlist.png";

const Home = () => {
  return (
    <div style={{ backgroundColor: "#f3f4f6", color: "#111827", fontFamily: "sans-serif" }}>
      {/* Hero Section */}
      <section style={{ textAlign: "center", padding: "5rem 2rem", background: "linear-gradient(to right, #3b82f6, #9333ea)", color: "white", position: "relative", overflow: "hidden" }}>
        <h1 style={{ fontSize: "3.5rem", fontWeight: "800", animation: "fadeIn 1s" }}>BarbersBook</h1>
        <p style={{ fontSize: "1.5rem", marginTop: "1rem", maxWidth: "600px", marginLeft: "auto", marginRight: "auto", animation: "slideUp 1s" }}>
          Track your earnings, expenses, and manage your barbershop like a pro!
        </p>
        <button style={{ marginTop: "2rem", padding: "1rem 2rem", backgroundColor: "white", color: "#3b82f6", fontWeight: "bold", borderRadius: "8px", cursor: "pointer", border: "none", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", transition: "transform 0.2s" }}
          onMouseOver={e => e.target.style.transform = "scale(1.05)"}
          onMouseOut={e => e.target.style.transform = "scale(1)"}>
          Download Now
        </button>
      </section>

      {/* Features Section */}
      <div style={{ maxWidth: "1100px", margin: "0 auto", padding: "4rem 1rem", display: "grid", gap: "3rem" }}>
        <FeatureSection title="Track Your Finances at a Glance" description="Stay on top of your business with a clear overview of today’s earnings, weekly and monthly expenses, and annual stats—all in one place." image={homeImage} />
        <FeatureSection title="Easily Log Your Services" description="Add each service with its time and amount in an intuitive calendar view. Keep your earnings organized and focus on cutting hair!" image={servicesImage} reverse />
        <FeatureSection title="Manage Your Expenses with Ease" description="Track your tax-deductible expenses to maximize your savings. Add supplies, rent, or other costs in seconds for better financial control." image={expensesImage} />
        <FeatureSection title="Streamline Walk-Ins with a Virtual Waitlist" description="Handle walk-in services effortlessly and share the waitlist with co-workers. Customers can join via the web for a professional experience." image={waitlistImage} reverse />
      </div>

      {/* Footer Section */}
      <footer style={{ textAlign: "center", padding: "3rem 2rem", backgroundColor: "#111827", color: "white" }}>
        <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>Download BarbersBook today!</h3>
        <p style={{ marginTop: "1rem", color: "#9ca3af" }}>Track your earnings, expenses, and manage your business like a pro.</p>
        <button style={{ marginTop: "1.5rem", padding: "1rem 2rem", backgroundColor: "#3b82f6", fontWeight: "bold", borderRadius: "8px", cursor: "pointer", border: "none", color: "white", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", transition: "transform 0.2s" }}
          onMouseOver={e => e.target.style.transform = "scale(1.05)"}
          onMouseOut={e => e.target.style.transform = "scale(1)"}>
          Get Started
        </button>
      </footer>
    </div>
  );
};

const FeatureSection = ({ title, description, image, reverse }) => (
  <section style={{ display: "flex", flexDirection: reverse ? "row-reverse" : "row", alignItems: "center", gap: "2rem", padding: "2rem", backgroundColor: "white", borderRadius: "10px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", transition: "box-shadow 0.2s", cursor: "pointer" }}
    onMouseOver={e => e.currentTarget.style.boxShadow = "0 8px 12px rgba(0, 0, 0, 0.15)"}
    onMouseOut={e => e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)"}>
    <img src={image} alt={title} style={{ width: "300px", borderRadius: "10px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", transition: "transform 0.2s" }}
      onMouseOver={e => e.target.style.transform = "scale(1.05)"}
      onMouseOut={e => e.target.style.transform = "scale(1)"} />
    <div style={{ flex: "1", textAlign: "left" }}>
      <h2 style={{ fontSize: "2rem", fontWeight: "700", color: "#3b82f6" }}>{title}</h2>
      <p style={{ fontSize: "1.2rem", marginTop: "1rem", color: "#374151" }}>{description}</p>
    </div>
  </section>
);

export default Home;