import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy.tsx";
import Home from "./Home.tsx";
import NotFoundPage from "./NotFound.tsx";
import WaitlistPage from "./Waitlist.tsx";

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="privacypolicy" element={<PrivacyPolicy />} />
      <Route path="waitlist/:shopName" element={<WaitlistPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
