import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      backgroundColor: "#ECEDEE",
      textAlign: "center",
      fontFamily: "Arial, sans-serif",
      color: "#151718",
    }}>
      <h1 style={{ fontSize: "4rem", marginBottom: "1rem", color: "#CD7F32" }}>404</h1>
      <h2 style={{ fontSize: "2rem", marginBottom: "1rem" }}>Page Not Found</h2>
      <p style={{ fontSize: "1.2rem", marginBottom: "2rem", color: "#8E8E93" }}>
        Sorry, the page you are looking for does not exist.
      </p>
      <Link
        to="/"
        style={{
          padding: "0.8rem 2rem",
          backgroundColor: "#CD7F32",
          color: "#fff",
          textDecoration: "none",
          borderRadius: "5px",
          fontSize: "1rem",
        }}
      >
        Go Back Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
