import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

interface WaitlistEntry {
  id: number;
  name: string;
  phone: string;
}

const MockedWaitlist: WaitlistEntry[] = [
  { id: 1, name: "John Doe", phone: "123-456-7890" },
  { id: 2, name: "Jane Smith", phone: "987-654-3210" },
  { id: 3, name: "Sam Wilson", phone: "555-123-4567" },
];

const WaitlistPage = () => {
  const { shopName } = useParams<{ shopName: string }>();
  const [waitlist, setWaitlist] = useState<WaitlistEntry[]>([]);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    // Simulate fetching the waitlist for the shop
    setTimeout(() => {
      setWaitlist(MockedWaitlist);
    }, 500); // Mocked delay for fetching data
  }, [shopName]);

  const handleAddToWaitlist = (e: React.FormEvent) => {
    e.preventDefault();

    if (name.trim() && phone.trim()) {
      const newEntry: WaitlistEntry = {
        id: waitlist.length + 1,
        name,
        phone,
      };

      setWaitlist((prev) => [...prev, newEntry]);
      setName("");
      setPhone("");
    } else {
      alert("Please fill out both fields.");
    }
  };

  return (
    <div
      style={{
        padding: "2rem",
        fontFamily: "Arial, sans-serif",
        maxWidth: "800px",
        margin: "0 auto",
        background: "linear-gradient(135deg, #FFD700, #FFA600)",
        borderRadius: "15px",
        boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
      }}
    >
      <h1
        style={{
          color: "#CD7F32",
          textAlign: "center",
          textShadow: "3px 3px 6px rgba(0, 0, 0, 0.3)",
          fontSize: "2.5rem",
        }}
      >
        Welcome to {shopName}
      </h1>

      <h2
        style={{
          marginTop: "1.5rem",
          color: "#151718",
          textAlign: "center",
          fontSize: "1.8rem",
        }}
      >
        Current Waitlist:
      </h2>
      {waitlist.length === 0 ? (
        <p style={{ textAlign: "center", color: "#8E8E93" }}>Loading...</p>
      ) : (
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {waitlist.map((entry) => (
            <li
              key={entry.id}
              style={{
                padding: "1rem",
                marginBottom: "0.75rem",
                backgroundColor: "#ECEDEE",
                borderRadius: "10px",
                color: "#151718",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s, box-shadow 0.3s",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "scale(1.03)";
                e.currentTarget.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.2)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
              }}
            >
              <strong>{entry.name}</strong> - {entry.phone}
            </li>
          ))}
        </ul>
      )}

      <h2
        style={{
          marginTop: "2rem",
          color: "#151718",
          textAlign: "center",
          fontSize: "1.8rem",
        }}
      >
        Add Yourself to the Waitlist
      </h2>
      <form
        onSubmit={handleAddToWaitlist}
        style={{
          marginTop: "1rem",
          backgroundColor: "#fff",
          padding: "2rem",
          borderRadius: "15px",
          boxShadow: "0 8px 15px rgba(0, 0, 0, 0.2)",
        }}
      >
        <div style={{ marginBottom: "1.5rem" }}>
          <label
            style={{
              display: "block",
              marginBottom: "0.5rem",
              color: "#151718",
              fontSize: "1rem",
            }}
          >
            Name:
          </label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              width: "100%",
              padding: "0.75rem",
              border: "1px solid #CD7F32",
              borderRadius: "8px",
              outline: "none",
              transition: "box-shadow 0.3s",
              fontSize: "1rem",
            }}
            onFocus={(e) => (e.target.style.boxShadow = "0 0 8px #CD7F32")}
            onBlur={(e) => (e.target.style.boxShadow = "none")}
          />
        </div>
        <div style={{ marginBottom: "1.5rem" }}>
          <label
            style={{
              display: "block",
              marginBottom: "0.5rem",
              color: "#151718",
              fontSize: "1rem",
            }}
          >
            Phone Number:
          </label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            style={{
              width: "100%",
              padding: "0.75rem",
              border: "1px solid #CD7F32",
              borderRadius: "8px",
              outline: "none",
              transition: "box-shadow 0.3s",
              fontSize: "1rem",
            }}
            onFocus={(e) => (e.target.style.boxShadow = "0 0 8px #CD7F32")}
            onBlur={(e) => (e.target.style.boxShadow = "none")}
          />
        </div>
        <button
          type="submit"
          style={{
            padding: "1rem 2.5rem",
            backgroundColor: "#CD7F32",
            color: "#fff",
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
            fontSize: "1rem",
            fontWeight: "bold",
            transition: "background-color 0.3s, transform 0.3s",
          }}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#B87333")}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#CD7F32")}
          onMouseDown={(e) => (e.currentTarget.style.transform = "scale(0.97)")}
          onMouseUp={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          Add to Waitlist
        </button>
      </form>
    </div>
  );
};

export default WaitlistPage;
